@font-face {
  font-family: "Texta";
  src:
    url("../fonts/texta-regular.woff2") format("woff2"),
    url("../fonts/texta-regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Texta";
  src:
    url("../fonts/texta-bold.woff2") format("woff2"),
    url("../fonts/texta-bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

body {
  overflow-x: hidden;
  font-family: "Texta", sans-serif;
  font-size: 16px;
  color: #000000;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: currentColor;
}

@supports (-webkit-backdrop-filter: blur(1px)) {
  svg {
    height: intrinsic;
  }
}

.lesson-list-masonry {
  display: flex;
}

.lesson-list-masonry-col > span {
  display: block;
  margin-bottom: 8px;
}

.lesson-list-masonry-col > span:last-child {
  margin-bottom: 0;
}
.ant-modal-close {
  color: rgba(0, 0, 0, 1);
}
.Annotationbxmodal .ant-modal-title,
.Annotationbxmodal .ant-modal-close {
  color: rgb(255 255 255);
}

.Openquestionbxmodal .ant-modal-header,
.Openquestionbxmodal .ant-modal-title {
  text-align: center;
  font-weight: bold;
  font-size: 18px;
  color: black;
}
.Openquestionbxmodal .ant-modal-body {
  padding: 20px;
}
.cancel-plan-survey-modal {
  padding-bottom: 0;
}

.cancel-plan-survey-modal .ant-modal-title {
  max-width: 1140px;
  margin: auto;
}
.cancel-plan-survey-modal .ant-modal-body {
  padding: 10px 24px;
}
.cancel-plan-survey-modal .cancel-plan {
  overflow: auto;
}
.cancel-plan-survey-modal .ant-modal-title,
.cancel-plan-survey-modal .ant-modal-close {
  color: #000000;
  font-size: 24px;
  font-weight: bold;
}

.cancel-plan-survey-modal .ant-modal-body .styles__Stepper-sc-13jyltr-0 {
  bottom: 20px;
}

.Annotationbxmodal .ant-modal-header {
  color: rgb(255 255 255);
  background: #0d0c0c;
}
@media (min-width: 1024px) {
  .instructionModal {
    width: 68% !important;
  }
}
@media (max-width: 1024px) {
  .instructionModal {
    width: 95% !important;
  }
}
.instructionModal .ant-modal {
  top: unset;
  vertical-align: bottom;
  bottom: 30px;
}

.instructionModal .ant-modal-header {
  color: rgb(255 255 255);
  background: #116699;
  border-radius: 10px 10px 0px 0px;
}

.instructionModal .ant-modal-content {
  border-radius: 10px;
}

.instructionModal .ant-modal-title {
  color: #fff;
}

.instructionModal .ant-modal-close-x {
  display: block;
  width: 35px;
  height: 35px;
  font-size: 16px;
  line-height: 37px;
  text-align: center;
  background: #fff;
  border-radius: 5px;
}

.instructionModal .ant-modal-close {
  position: absolute;
  top: 8px;
  right: 10px;
}

.instructionModal .ant-modal-close svg {
  color: #116699;
}

.Openfeedbackmodal {
  width: 55%;
}
@media (max-width: 767px) {
  .Openfeedbackmodal {
    width: 95% !important;
  }
}

.studentresponsemodal .ant-modal-body {
  background: #f8f8f8;
}
.quiz-alert-modal .ant-modal-body {
  background: none;
}
.timerWarning .ant-modal-body {
  display: none;
}
.timerWarning .ant-modal-header {
  background: #000;
}
.timerWarning .ant-modal-header .ant-modal-title {
  color: #fff;
  font-weight: bold;
  font-size: 20px;
}
.studentresponsemodal .ant-modal-close-x {
  width: 43px;
  height: 43px;
  font-size: 26px;
  line-height: 49px;
}
.studentresponsemodal .ant-modal-header {
  padding: 6px 43px 6px 24px;
}
.studentresponsemodal .ant-modal-body {
  padding: 0;
}

.timerWarning .ant-modal-close-x svg {
  fill: #fff;
}

.ant-table-ping-right:not(.ant-table-has-fix-right) .ant-table-container::after,
.ant-table-ping-left:not(.ant-table-has-fix-left) .ant-table-container::before {
  box-shadow: none;
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}
::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: #cdcdcd;
  border-radius: 10px;
}

.modal-dark-header .ant-modal-header {
  background: #000;
  color: #fff;
}
.modal-dark-header .ant-modal-header h1,
.modal-dark-header .ant-modal-header p,
.modal-dark-header .ant-modal-close {
  color: #fff;
}

.modal-scrollable .ant-modal-body {
  max-height: calc(100vh - 45px);
  overflow: auto;
}
.modal-full-screen .ant-modal-body {
  height: calc(100vh - 45px);
}
.modal-full-screen.ant-modal {
  max-width: calc(100vw - 0px);
}
.modal-border-radius .ant-modal-header {
  border-radius: 8px 8px 0 0;
}
.modal-border-radius .ant-modal-content {
  border-radius: 8px;
}
.modal-border-radius .ant-modal-body {
  border-radius: 0 0 8px 8px;
}

.ant-popover .ant-popover-inner {
  border-radius: 50px;
}

.w-100 {
  width: 100% !important;
}
.text-nowrap {
  white-space: nowrap;
}
@media (max-width: 767px) {
  .ant-modal.modal-full-screen {
    margin: 0px auto;
  }
}

@media (max-width: 576px) {
  .gap-y-10 {
    gap: 10px 0;
  }
  .gap-x-10 {
    gap: 0px 10px;
  }
}

@media (min-width: 576px) {
  .text-sm-center {
    text-align: center;
  }
  .text-sm-right {
    text-align: right;
  }
}
.width-lg-950 {
  width: 98% !important;
}
.notification-modal .ant-modal-body {
  height: 350px;
  padding: 15px 24px;
  overflow: auto;
}
@media (min-width: 992px) {
  .width-lg-950 {
    width: 950px !important;
  }
  .notification-modal {
    width: 700px !important;
  }
}

.ant-modal-body-bg-color .ant-modal-body {
  background-color: #fff;
}
